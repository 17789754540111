import * as Sentry from '@sentry/nextjs';

import React, { useEffect } from "react";

import { SearchContext } from "./context";
import axios from "utils/api/axios-client";
import { getPathForAirportSearchResults } from 'utils/routing'
import qs from "qs";
import { useRouter } from 'next/router'
import useSWR from "swr";

const getFilterFields = (query) => {
    const {
        sortBy,
        transferTimeMinutes,
        hasParkMarkAward,
        isMeetAndGreet,
        isOnAirport,
        isOffAirport,
        isMeetOnArrival,
        isMeetOnReturn,
        isParkAndRide,
        hasEvCharging,
    } = query;
    let fields = {};
    if (!!sortBy) fields['sortBy'] = sortBy;
    if (!!transferTimeMinutes && transferTimeMinutes < 30) fields['transferTimeMinutes[lte]'] = transferTimeMinutes;
    if (hasParkMarkAward == 'true') fields['hasParkMarkAward'] = true;
    if (isMeetAndGreet == 'true') fields['isMeetAndGreet'] = true;
    if (isOnAirport == 'true') fields['isOnAirport'] = true;
    if (isOffAirport == 'true') fields['isOffAirport'] = true;
    if (isMeetOnArrival == 'true') fields['isMeetOnArrival'] = true;
    if (isMeetOnReturn == 'true') fields['isMeetOnReturn'] = true;
    if (isParkAndRide == 'true') fields['isParkAndRide'] = true;
    if (hasEvCharging == 'true') fields['hasEvCharging'] = true;
    return fields;
}

export default function SearchResultsProvider({
    children,
    airport,
    productsMap,
}) {
    const { isReady, query, pathname, replace: navigate } = useRouter();
    const { uuid } = query;
    const [error, setError] = React.useState(undefined);
    const [forceLoading, setForceLoading] = React.useState(false);

    // always re-set loading to false upon a navigation event
    useEffect(() => {
        setForceLoading(false);
    }, [pathname, query]);

    const hasRequiredFields = query.arrivalDate && query.arrivalTime && query.departureDate && query.airport;

    useEffect(() => {
        if (isReady && !uuid && pathname.endsWith("results") && hasRequiredFields) {
            const { arrivalDate, arrivalTime, departureDate, departureTime, airport: queryAirport, ...otherQuery } = query;
            const baseSearchParams = {
                arrivalDate,
                departureDate,
                arrivalTime: arrivalTime?.trim(),
                departureTime: departureTime?.trim(),
            };
            const payload = {
                airportIataCode: airport.iataCode,
                ...baseSearchParams
            };

            axios.post('/api/parking-search', payload)
                .then(response => {
                    const searchUuid = response.data.uuid

                    if (window.dataLayer) window.dataLayer.push({
                        event: 'search',
                        airport: airport.iataCode,
                        arrivalDate,
                        arrivalTime,
                        departureDate,
                        departureTime,
                        searchUuid,
                    })

                    navigate({
                        pathname: getPathForAirportSearchResults(airport.slug),
                        query: {
                            uuid: searchUuid,
                            ...baseSearchParams,
                            ...otherQuery
                        },
                        hash: 'results',
                    }, undefined, { scroll: false });
                })
                .catch(error => {
                    setError(error);
                    Sentry.captureException(error);
                    console.error("Error Data", error?.response?.data);
                    console.error(error);
                });
        }
    }, [isReady, uuid, airport?.iataCode, airport?.slug, pathname, query, navigate, setError, hasRequiredFields]);

    const { data: { results, ...data } = {}, isValidating } = useSWR(
        () => {
            const filterFields = getFilterFields(query);
            return uuid ? `/api/parking-search/${uuid}?${qs.stringify(filterFields)}` : null;
        },
        {
            refreshInterval: dat => dat?.complete ? 0 : 350,
            onError: (error) => {
                setError(error);
                Sentry.captureException(error);
                console.error("Error Data", error?.response?.data);
                console.error("Error", error);
            },
            onSuccess: () => setError(undefined),
        }
    );

    const enrichedResults = React.useMemo(() => (results || []).map(
        result => ({ ...result, productData: productsMap[result.productCode] })
    ), [results, productsMap]);

    const onSearchChange = React.useCallback((newValues, { fullRefresh } = {}) => {
        setError(undefined);
        const { airport: queryAirport, ...oldQuery } = query;
        const queryValues = {
            ...oldQuery,
            ...newValues,
        };
        if (queryValues.airport) delete queryValues['airport'];
        if (fullRefresh) {
            delete queryValues['uuid'];
            setForceLoading(true);
        }

        const noUndefinedQuery = Object.fromEntries(
            Object.entries(queryValues)
                .filter(([, value]) => typeof value !== 'undefined')
        );

        navigate({
            pathname: getPathForAirportSearchResults(newValues.airport || airport?.slug || queryAirport),
            query: noUndefinedQuery,
            hash: 'results'
        }, undefined, { scroll: false });
    }, [navigate, query, airport?.slug]);

    const value = {
        // data: forceLoading ? null : data,
        data,
        uuid,
        // results: forceLoading ? [] : enrichedResults,
        results: enrichedResults,
        onSearchChange,
        isLoading: isValidating || forceLoading,
        error,
        forceLoading,
        hasRequiredFields,
        airport,
    };

    return (
        <SearchContext.Provider value={value}>
            {children}
        </SearchContext.Provider>
    )
}
